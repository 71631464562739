<template>
  <div
    v-if="loaderState"
    id="loading"
  >
    <div class="dot-flashing"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loaderState: Boolean
  }
}
</script>
